import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { useStaticQuery, Link } from "gatsby";
import SEOHeader from "../components/seo-header";
export default function Personal() {
  const data = useStaticQuery(graphql`
    query PersonalTwoQuery {
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "Personal" } } }
        sort: { fields: frontmatter___order }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              order
              featuredImage {
                relativeDirectory
                publicURL
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEOHeader
        title="Moments - Silvergray Photography by Pranaav BR"
        description="Pranaav produces unique, captivating photography, individually designed and curated for concepts and campaigns."
        pinterest="true"
      ></SEOHeader>

      <Layout>
        <div className="container-fluid">
          <div className="row">
            {data.allMarkdownRemark.edges.map((post) => {
              var path = post.node.frontmatter.featuredImage.childImageSharp
                .fluid
                ? post.node.frontmatter.featuredImage.childImageSharp.fluid.src
                : "";
              return (
                <div
                  className="col-md-3"
                  style={{ padding: "0px", margin: "0px" }}
                >
                  <Link
                    to={"/personal" + post.node.fields.slug}
                    className="galleryBox"
                    style={{
                      backgroundImage: "url(" + path + ")",
                    }}
                  >
                    {" "}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </div>
  );
}
